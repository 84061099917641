<template>
  <div class="message" :class="messageClass">
    <div class="content">
      <table-message v-if="displayType == 'table'" :message="message" />
      <chart-message v-if="displayType == 'chart'" :message="message" />
      <handlebars-message v-if="displayType == 'handlebars'" :message="message" />
      <text-message v-if="displayType == 'text'" :message="message" />
    </div>
    <div v-if="message.options !== undefined && message.options.length > 0" class="options">
      <clickable-message v-for="(option, index) in message.options" :key="index">{{option.name}}</clickable-message>
    </div>

    <div v-if="suggestions.length > 0" class="suggestions">
      <div v-if="suggestionsVisible">
        <clickable-message v-for="(suggestion, index) in suggestions" :key="suggestion" :tabindex="index+1" buttonClass="btn clickable-message" tag="div" :message="suggestion">{{suggestion}}</clickable-message>
      </div>
      <div v-else>
        <div class="title">We found {{suggestions.length}} related questions</div>
        <a @click="showSuggestions" class="show-suggestions button">
          <i class="fa fa-plus" />Show Related Data
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import tableMessage from "./outputs/table-message.vue";
import textMessage from "./outputs/text-message.vue";
import chartMessage from "./outputs/chart-message.vue";
import handlebarsMessage from "./outputs/handlebars-message.vue";

import ClickableMessage from "./clickable-message.vue";

export default {
  name: "message",
  props: {
    message: Object,
    debug: Boolean,
    avatarUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    tableMessage,
    textMessage,
    chartMessage,
    handlebarsMessage,
    ClickableMessage,
  },
  data() {
    return {
      forceSuggestionsShow: false,
    };
  },
  computed: {
    suggestionsVisible() {
      return this.suggestions.length < 10 || this.forceSuggestionsShow;
    },
    messageClass() {
      const val = [];
      if (this.isInbound) val.push("inbound");
      if (this.isOutbound) val.push("outbound");
      val.push(this.displayType);
      return val.join(" ");
    },
    isInbound() {
      return this.message.author !== "huddle";
    },
    isOutbound() {
      return !this.isInbound;
    },
    showAvatar() {
      return (
        this.avatarUrl !== undefined &&
        this.avatarUrl !== null &&
        this.avatarUrl != ""
      );
    },
    displayType() {
      console.log("displayType", this.message);
      if (this.message.data && this.message.data.type) {
        return this.message.data.type;
      } else {
        return "text";
      }
    },
    suggestions() {
      if (this.message.data && this.message.data.suggestions)
        return this.message.data.suggestions;
      else return [];
    },
  },
  methods: {
    showSuggestions() {
      this.forceSuggestionsShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin: 11px 0;
  max-width: 90%;
  &.chart {
    width: 100%;
  }
  .content {
    padding: 7px 7px;
    border-radius: 5px;

    p {
      margin: 5px;

    }
  }

  .options {
    margin: 4px;
  }

  &.outbound {
    align-self: flex-end;

    .content {
      background-color: #eeeeee;
      color: #606060;

      /deep/ blockquote {
        font-size: 1.3em;
        position: relative;
        line-height: 25px;
        text-align: center;
        margin: 30px;

        &:before {
          content: "\00201C";
          color: #c7c7c7;
          margin-right: 5px;
          font-size: 2em;
        }
        &:after {
          margin-left: 5px;
          content: "\00201D";
          color: #c7c7c7;
          font-size: 1.8em;
        }
      }
    }
  }
  &.inbound {
    .content {
      background-color: #008833;
      color: white;
      float: left;
    }
  }
  .suggestions {
    margin: 14px 5px;
    text-align: right;

    .button {
      display: inline-block;
      background-color: #008833;
      color: white;
      margin: 2px;
      padding: 0px 7px;
      border-radius: 5px;
      line-height: 30px;
      font-size: 0.9em;
      text-decoration: none;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #006611;
      }

      &.show-suggestions {
        margin: 10px;
      }
    }
  }
}
</style>
