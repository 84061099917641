
import Handlebars from "handlebars";

Handlebars.registerHelper({
  eq: function (v1, v2) {
    return v1 === v2;
  },
  ne: function (v1, v2) {
    return v1 !== v2;
  },
  lt: function (v1, v2) {
    return v1 < v2;
  },
  gt: function (v1, v2) {
    return v1 > v2;
  },
  lte: function (v1, v2) {
    return v1 <= v2;
  },
  gte: function (v1, v2) {
    return v1 >= v2;
  },
  and: function () {
    return Array.prototype.slice.call(arguments).every(Boolean);
  },
  or: function () {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  }
});

Handlebars.registerHelper("percent", function (value1, value2) {
  return (parseFloat(value1) / parseFloat(value2)) * 100;
});

Handlebars.registerHelper("gt", function (value1, value2) {
  return value1 > value2;
});

Handlebars.registerHelper("formatPercent", function (value) {
  return `${parseInt(value)}%`;
});

Handlebars.registerHelper("formatCurrency", function (value) {
  return `£${parseFloat(value)}`;
});

Handlebars.registerHelper("formatNumber", function (value) {
  return `${parseInt(value)}`;
});

Handlebars.registerHelper({
  eq: function (v1, v2) {
    return v1 === v2;
  },
  ne: function (v1, v2) {
    return v1 !== v2;
  },
  lt: function (v1, v2) {
    return v1 < v2;
  },
  gt: function (v1, v2) {
    return v1 > v2;
  },
  lte: function (v1, v2) {
    return v1 <= v2;
  },
  gte: function (v1, v2) {
    return v1 >= v2;
  },
  and: function () {
    return Array.prototype.slice.call(arguments).every(Boolean);
  },
  or: function () {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  }
});

Handlebars.registerHelper("filterHtml", function(value) {
  var temp = document.createElement("div");
  temp.innerHTML = value;
  return temp.textContent || temp.innerText;
})

Handlebars.registerHelper("percent", function (value1, value2) {
  return (parseFloat(value1) / parseFloat(value2)) * 100;
});

Handlebars.registerHelper("gt", function (value1, value2) {
  return value1 > value2;
});

Handlebars.registerHelper("formatPercent", function (value) {
  if (isNaN(value)) {
    return new Handlebars.SafeString('<span class="data-point is-percentage not-available">N/A</span>')
  } else {
    return new Handlebars.SafeString(`<span class="data-point is-percentage">${parseInt(value).toLocaleString(undefined, { maximumFractionDigits: 0 })}%</span>`)
  }
});

Handlebars.registerHelper("formatCurrency", function (value) {
  if (isNaN(value)) {
    return new Handlebars.SafeString('<span class="data-point is-currency not-available">N/A</span>')
  } else {
    return new Handlebars.SafeString(`<span class="data-point is-currency">£${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>`)

    undefined, { minimumFractionDigits: 4 }
  }
});

Handlebars.registerHelper("formatNumber", function (value) {
  if (isNaN(value)) {
    return new Handlebars.SafeString('<span class="data-point is-number not-available">N/A</span>')
  } else {
    return new Handlebars.SafeString(`<span class="data-point is-number">${parseInt(value).toLocaleString(undefined, { maximumFractionDigits: 0 })}</span>`)
  }
});

let latest = ""

const HandlebarsHelper = {
  render(template, data) {
    try {
      const handlebars = Handlebars.compile(template);
      console.log(latest, data)
      latest = handlebars(data);
      return latest
    } catch (e) {
      console.warn("invalid syntax for handlebars")
      return latest;
    }
  }
}

export default HandlebarsHelper