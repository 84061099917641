import huddle from '../store/modules/huddle'
import api from '../api'

const setup = {
  initApi (Vue, options) {
    api.init(options)
  },
  initStore (Vue, options) {
    console.log('Initializing store for huddle-chat')
    const store = options.store
    console.debug("huddle store:", huddle)
    store.registerModule(`huddle`, huddle)
  }

}

export default setup
