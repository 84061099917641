import axios from 'axios'
import api from '../../api'
import HTTP from '../http'

export default {
  async login (token) {
    const localHttp = axios.create({
      baseURL: HTTP.defaults.baseURL
    })

    const response = await localHttp.post('/authentication/create', { token: token })
    return response
  }
}
