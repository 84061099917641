import Vue from 'vue'
import ChatChannelHandler from './chatHandler'

import SocketClient from './client'

const SocketHandler = {
  subscribeAll (store) {
    this.subscribeChat(store)
  },
  subscribeChat (store) {
    console.log("Subscribing to chat...")
    if (store.state.huddle.session && store.state.huddle.session.current.id) {
      console.log('socket', store.state.huddle.socket)
      if (store.state.huddle.socket.isConnected) {
        this.subscribe({
          channel: 'ApplicationCable::ChatChannel',
          session_id: store.state.huddle.session.current.id,
          token: store.state.huddle.config.session.token
        }, store)
      } else {
        console.log("There is no connection, scheduling a retry")
        window.setTimeout(
          function () {
            console.log('Retry subscribe to chat')
            this.subscribeChat(store)
          }.bind(this),
          1000
        )
      }
    } else {
      console.warn("No session information so not subscribing to chat")
    }
  },
  subscribe (params = {}, store) {
    const msg = {
      command: 'subscribe',
      identifier: JSON.stringify(params)
    }
    try {
      if (store.state.huddle.socket.isConnected) {
        SocketClient.socket.send(JSON.stringify(msg))
      }
    } catch (e) {
      console.warn('Error Connecting on socket', e)
    }
  },
  handle (data, store) {
    if (data.identifier) {
      const identifier = JSON.parse(data.identifier)
      if (identifier.channel) {
        switch (identifier.channel) {
          case 'ApplicationCable::ChatChannel':
            ChatChannelHandler.handle(data, store)
            break
          default:
            break
        }
      }
    }
  }
}

export default SocketHandler
