import HTTP from '../http'

export default {
  async create (sessionId, message) {
    try {
      const response = await HTTP.post(`/sessions/${sessionId}/messages`, {
        message: message
      })
      return response
    } catch (e) {
      store.dispatch('application/handleError', e)
    }
  }
}
