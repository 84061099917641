import http from './http'
import message from './modules/message'
import session from './modules/session'
import config from './modules/config'

export default {
  message: message,
  session: session,
  config: config,
  store: undefined,
  endpoint: undefined,
  init (options) {
    this.endpoint = options.endpoint
    this.store = options.store
    http.defaults.baseURL = options.endpoint

    http.interceptors.request.use(
      function (config) {
        const token = options.store.state.huddle.config.session.token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept'] = 'application/json'
        return config
      },
      function (err) {
        console.log(err)
        return Promise.reject(err)
      }
    )

    http.interceptors.response.use(
      response => {
        return response
      },
      error => {
        console.log(error)
        return error
      }
    )
  }
}
