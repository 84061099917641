import HTTP from '../http'

export default {
  async create () {
    try {
      const response =  await HTTP.post('/sessions')
      console.log("create session response", response)
      return response.data
    } catch (e) {
      return e.response
    }
  },
  async get(id) {
    try {
      const response =  await HTTP.get(`/sessions/${id}`)
      return response.data
    } catch (e) {
      store.dispatch('application/handleError', e)
    }
  },
  async latest () {
    try {
      const response = await HTTP.get('/sessions')
      const id = response.data
        .map(x => x.id)
        .sort(function (a, b) {
          a - b
        })
        .reverse()[0]
      return id
    } catch (e) {
      store.dispatch('application/handleError')
    }
  }
}
