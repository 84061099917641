import SocketHandler from '../../../socket'
import Vue from 'vue'

const state = {
  isConnected: false,
  message: '',
  reconnectError: false
}

const actions = {
  setConnected ({ commit }, isConnected) {
    commit('setConnected', isConnected)
  }
}

const mutations = {
  setConnected (state, isConnected) {
    state.isConnected = isConnected
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
