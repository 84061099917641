<template>
  <div class="text-container" :class="{'collapsed': collapsed}">
    <div class="text" v-html="text" />
    <div class='expand-link' v-if="showExpand && collapsed">&hellip;<a href="#" @click="expand">More</a></div>
  </div>
</template>

<script>
export default {
  name: "text-message",
  props: {
    message: {
      type: Object,
      required: true
    },
    maxWords: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      collapsed: true
    };
  },
  computed: {
    messageText() {
      if (this.message.data) {
        return this.message.data.text;
      } else {
        return this.message.text;
      }
    },
    text() {
      if (this.collapsed) {
        return this.messageText.split(" ").splice(0, this.maxWords - 1).join(" ");
      } else {
        return this.messageText
      }
    },
    options() {
      return this.message.options || [];
    },
    showOptions() {
      return this.options && this.options.length > 0;
    },
    showExpand() {
      return this.messageText.split(" ").length > this.maxWords;
    }
  },
  methods: {
    expand() {
      this.collapsed = false
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.text p {
  margin: 2px 0;
}

.text-container {
  display: flex;

  .expand-link {
    align-self: flex-end;
    white-space: nowrap;

    a {
      margin-left: 5px;
    }
  }
}


</style>

