const ChatHandler = {
  handle (data, store) {
    console.log('ChatHandler', data)
    if (data.message) {
      switch (data.message.name) {
        case 'message_delivered':
          const author = data.message.inbound ? 'user' : 'huddle'
          if (data.message.data) {
            store.dispatch('huddle/message/pushMessage', {
              id: data.message.id,
              options: data.message.options,
              readingAge: data.message.reading_age,
              data: Object.assign(
                {
                  text: data.message.text
                },
                data.message.data
              ),
              author: author,
              type: 'text'
            })
          } else if (data.message.text) {
            store.dispatch('huddle/message/pushMessage', {
              id: data.message.id,
              options: data.message.options,
              readingAge: data.message.reading_age,
              text: data.message.text,
              author: author,
              type: 'text'
            })
          } else if (data.responding_to) {
          }
          break
        // case "session_updated":
        //   store.dispatch("chat/updateSession", data.message.session)
        //   break;
        // case "report_ready":

        //   console.log(data.message.report)

        //   data.message.report.messages = data.message.report.messages.map(function (msg) {
        //     console.log("msg:data", msg.data)

        //     const msgData = Object.assign({
        //       text: msg.text
        //     }, msg.data)

        //     console.log("msg:newdata", msgData)

        //     return Object.assign(msg, {
        //       data: msgData
        //     })
        //   })

        //   console.log(data.message.report.messages)

        //   store.dispatch("report/reportReady", data.message.report)

        //   break;
      }
    }
  }
}

export default ChatHandler
