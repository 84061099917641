<template>
  <div class="input" :class="{border : border}">
    <label style="display:none;" for="input">Chat Input Field</label>
    <input v-model="inputText" aria-label="Chat Input" @keyup.up="navigateUp()" @keyup.down="navigateDown()" @keyup.enter="submit()" type="text" :placeholder="placeholder" autocomplete="chat-input" name="chat-input" id="chat-input" />
    <a class="clickable btn btn-primary" @click="submit()" :class="{active : isButtonActive}">Send</a>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "message-input",
  props: {
    border: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputText: "",
      inputHistory: [],
      historyIndex: 0
    };
  },
  methods: {
    submit() {
      const message = { text: this.inputText, author: "user", type: "text" };
      this.$store.dispatch("huddle/message/send", message );

      this.inputHistory.push(this.inputText);
      this.inputText = "";
      this.historyIndex = this.inputHistory.length;
    },
    navigateUp() {
      console.log("navigateUp", this.historyIndex)
      this.historyIndex = this.historyIndex - 1;
      if (this.historyIndex < 0) this.historyIndex = 0;
      this.inputText = this.inputHistory[this.historyIndex];
    },
    navigateDown() {
      this.historyIndex = this.historyIndex + 1;
      if (this.historyIndex > this.inputHistory.length)
        this.historyIndex = this.inputHistory.length;
      this.inputText = this.inputHistory[this.historyIndex];
    }
  },
  computed: {
    ...mapState({
      placeholder: state => state.huddle.config.placeholder
    }),
    isButtonActive() {
      return this.inputText !== "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/palette.scss";
.input {
  &.border {
    border: 1px solid $light-border;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  margin-top: 20px;
  width: 100%;
  display: flex;
  padding: 0px 0px 0px 5px;

  input {
    width: 100%;
    border: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  a {
    cursor: pointer;
    padding: 0px 12px;
    line-height: 30px;
    height: 30px;
    border-radius: 5px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
}
</style>
