import api from '../../../api'
import SocketHandler from '../../../socket'

const state = {
  botName: 'henry',
  current: {},
  all: [],
  awaitingResponse: false,
  loading: {
    all: false,
    current: false
  }
}

const actions = {
  async create({commit}) {
    const data = await api.session.create()
    console.log("storing session", data)
    commit('storeSession', data)
  }
}

const mutations = {
  storeSession(state, session) {
    state.current = session
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
