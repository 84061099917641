<template>
  <div class="table-content">
    <h1 class="title">{{displayData | titleize}}</h1>
    <table class="table">
      <tr>
        <th v-for="(header, index) in tableHeaders" :key="`${id}-${index}-${header}`">{{header}}</th>
      </tr>
      <tr v-for="(row, index) in tableData" :key="`${id}-${index}-${row[0]}`">
        <td v-for="col in row" :key="`${id}-${index}-${row[0]}-${col}`">{{col}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "table-message",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    data() {
      return this.message.data
    },
    id() {
      return this._uid;
    },
    title() {
      return Object.keys(this.data.results[0])
        .filter(
          function(k) {
            return (
              ["return-year", "landlord-name", "timestamp"].indexOf(k) == -1
            );
          }.bind(this)
        )
        .map(
          function(k) {
            let dataPoint = this.data.results[0][k].data_point;
            return dataPoint.display_name || dataPoint.name;
          }.bind(this)
        )
        .join(", ");
    },
    tableHeaders() {
      console.log("tableHeaders", this.data.results[0])
      let fieldNames = Object.keys(this.data.results[0]).filter(function(k) {
        return k !== "timestamp";
      });
      return fieldNames.map(
        function(k) {
          return (
            this.data.results[0][k].data_point.display_name ||
            this.data.results[0][k].data_point.name
          );
        }.bind(this)
      );
    },
    displayData() {},
    tableData() {
      return this.data.results.map(function(r) {
        return Object.keys(r)
          .filter(function(k) {
            return k !== "timestamp";
          })
          .map(function(k) {
            return r[k]["formatted_value"];
          });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/palette.scss";

.table {
  width: 100%;
  background-color: inherit;
  td,
  th {
    padding: 2px 5px;
    margin: 0;
  }
  th {
    font-weight: 700;
    color: inherit;
  }
}
</style>

