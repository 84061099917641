<template>
  <div class="tile is-parent is-vertical">
    <article class="tile is-child notification is-link">
      <div class="chart">
        <apexchart :type="chartType" height="300" :options="chartOptions" :series="series" />
      </div>
    </article>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "chart-message",
  components: {
    apexchart: VueApexCharts
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartType() {
      return this.message.data.chart_type || "line";
    },
    title() {
      return Object.keys(this.results[0][0])
        .filter(function(key) {
          return (
            ["return-year", "timestamp", "landlord-name"].indexOf(key) == -1
          );
        })
        .map(
          function(k) {
            let dataPoint = this.results[0][0][k].data_point;
            return this.titleize((dataPoint.display_name || dataPoint.name).replace(/_/g, " "));
          }.bind(this)
        )
        .join(", ");
    },
    xAxis() {
      return this.message.data.x_axis.map(label =>
        this.$moment(label).format("MMM DD")
      );
    },
    series() {
      let s = [];
      return this.message.data.series;
    },
    chartOptions() {
      return {
        tooltip: {
          theme: "dark"
        },
        colors: ["#B3294B", "#666", "#111"],
        chart: {
          height: 300,
          type: this.chartType
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            if (val !== undefined) {
              return val.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
            }
          }
        },
        xaxis: {
          type: "datetime",
          categories: this.xAxis,
          labels: {
            formatter: function(value) {
              return this.$moment(value).format("MMM DD");
            }.bind(this)
          }
        },
        yaxis: {
          categories: this.xAxis,
          labels: {
            formatter: function(value) {
              if (value !== undefined) {
                return value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
              }
            }
          }
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "square",
          colors: undefined,
          width: 5,
          dashArray: 0
        },
        title: {
          text: this.title,
          floating: true,
          align: "center",
          style: {
            color: "#333"
          }
        }
      };
    },
    results() {
      return Array.isArray(this.message.data.results[0])
        ? this.message.data.results
        : [this.message.data.results];
    }
  },
  methods: {
    titleize(sentence) {
      if (!sentence.split) return sentence;
      var _titleizeWord = function(string) {
          return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        result = [];
      sentence.split(" ").forEach(function(w) {
        result.push(_titleizeWord(w));
      });
      return result.join(" ");
    }
  }
};
</script>


<style lang="scss" scoped>
.chart {
  width: 100%;
  display: block;
  margin: 20px auto;
}

.apexcharts-tooltip.light {
  color: #333 !important;
}

.metadata {
  text-align: right;
  border-top: 1px solid #ccc;
  width: 100%;
}
.metadata .item {
  margin-left: 14px;
}
.metadata .item .value {
  font-weight: bold;
}
</style>
