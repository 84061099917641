<template>
  <div class="chat">
    <div class="messages" v-chat-scroll>
      <message v-for="message in messages" :key="message.id" :message="message" />
    </div>
    <message-input />
  </div>
</template>

<script>
import message from "./message";
import messageInput from "./message-input";

import { mapState } from "vuex";

export default {
  name: "Chat",
  components: {
    message,
    messageInput
  },
  computed: {
    ...mapState({
      messages: state => state.huddle.message.history
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/palette.scss";

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;

  .input {
    flex: 0;
  }
  .messages {
    min-height: 100px;
    max-height: 100vh;
    overflow: scroll;
    padding: 20px;

    flex: 1;

    display: flex;
    flex-direction: column;

    &.border {
      border: 1px solid $light-border;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
</style>
