import api from '../../../api'

const state = {
  history: [],
  awaitingResponse: false,
  loading: {
    all: false,
    current: false
  }
}

const actions = {
  async send ({ commit, rootState }, msg) {
    console.log('rootState', rootState)
    commit('setAwaitingResponse', true)
    const response = await api.message.create(
      rootState.huddle.session.current.id,
      msg
    )
  },
  pushMessage ({ commit }, message) {
    console.log('pushMessage in module')
    commit('addMessage', message)
    commit('setAwaitingResponse', false)
  }
}

const mutations = {
  addMessage (state, message) {
    state.history.push(message)
  },
  setAwaitingResponse (state, awaiting) {
    state.awaitingResponse = awaiting
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
