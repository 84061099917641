import chatContainer from './chat-container.vue'
import setup from './lib/setup'
import VueChatScroll from 'vue-chat-scroll'

export default {
  install (Vue, options) {
    if (!options || !options.store) {
      throw new Error('Please initialise plugin with a Vuex store.')
    }
    if (!options || !options.endpoint) {
      options.endpoint = 'https://api.huddleai.com/'
    }

    Vue.use(VueChatScroll)

    if (!Vue.moment) {
      Vue.use(require('vue-moment'))
    }
    console.log('Installing vue-huddle-chat', options)
    Vue.component('vue-huddle-chat', chatContainer)

    setup.initStore(Vue, options)
    setup.initApi(Vue, options)
  }
}
