
<template>
  <component @click="clicked" :is="tag" :tabindex="tabindex" class="clickable-message" :disabled="loading" :class="[{loading: loading}, buttonClass]">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "ClickableMessage",
  props: {
    label: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
    tag: {
      type: String,
      default: "a",
    },
    tabindex: {
      type: Number,
      default: -1,
    },
    message: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    messageToSend() {
      return this.message || this.$slots.default[0].text;
    },
  },
  methods: {
    clicked() {
      if (this.loading) {
        return;
      }
      const message = {
        text: this.messageToSend,
        author: "user",
        type: "text",
      };

      this.loading = true;
      this.$store.dispatch("huddle/message/send", message).then(
        function () {
          this.loading = false;
        }.bind(this)
      );
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable-message {
  display: inline-block;
  border-radius: 5px;
  background-color: #083;
  margin: 3px;
  padding: 3px 5px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.7em;

  &.loading {
    opacity: 0.8;
  }

  &:hover {
    background-color: #072;
  }
}
</style>
