import config from './huddle/config'
import session from './huddle/session'
import message from './huddle/message'
import socket from './huddle/socket'

const state = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    config,
    session,
    message,
    socket
  }
}
