<template>
  <chat />
</template>

<script>
import Vue from "vue";
import setup from "./lib/setup";
import chat from "./components/chat";

import SocketClient from "./socket/client";

import { mapState } from "vuex";

const wsEndpoint = process.env.VUE_APP_WS_URL || 'wss://api.huddleai.com/ws'

export default {
  name: "vue-huddle-chat",
  props: {
    endpoint: {
      type: String,
      default: wsEndpoint,
    },
    token: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Ask me something",
    },
    intro: {
      type: Array,
      default: () => [],
    },
    introInterval: {
      type: Number,
      default: 1000,
    },
    example: {
      type: String,
      default: undefined,
    },
    notification: {
      type: Boolean,
      default: false,
    },
    notificationUrls: {
      type: Object,
      default: () => ({
        "audio/mpeg": "https://www.huddleai.com/notification.mp3",
        "audio/ogg": "https://www.huddleai.com/notification.ogg",
      }),
    },
  },
  components: {
    chat,
  },
  computed: {
    ...mapState({
      messages: (state) => state.huddle.message.history,
    }),
    messageCount() {
      return this.messages.length;
    },
  },
  watch: {
    placeholder() {
      this.$store.dispatch("huddle/config/setPlaceholder", this.placeholder);
    },
    messageCount() {
      const lastMessage = this.messages[this.messageCount - 1];
      if (lastMessage.author !== "user" && this.notification) {
        this.playSound();
      }
    },
  },
  methods: {
    playSound() {
      console.debug("Playing notification sound");
      if (document.getElementById("huddle-notification") == null) {
        let p = document.createElement("div");
        p.id = "huddle-notification";
        document.querySelector("body").appendChild(p);
      }

      var embedSource =
        '<embed hidden="true" autostart="true" loop="false" src="' +
        this.notificationUrls["audio/mpeg"] +
        '.mp3">';
      document.getElementById("huddle-notification").innerHTML =
        '<audio autoplay="autoplay">' +
        Object.keys(this.notificationUrls).map(
          (key) =>
            '<source src="' +
            this.notificationUrls[key] +
            '" type="' +
            key +
            '">'
        ) +
        embedSource +
        "</audio>";
    },
  },
  created() {
    console.log(this.endpoint);
    if (this.endpoint !== "") {
      this.$store.dispatch("huddle/config/setEndpoint", this.endpoint).then(
        function () {
          if (this.token !== "") {
            this.$store
              .dispatch("huddle/config/setToken", this.token)
              .then(() =>
                this.$store
                  .dispatch("huddle/config/createAuthSession")
                  .then(() =>
                    this.$store.dispatch("huddle/session/create").then(() => {
                      const options = {
                        store: this.$store,
                        endpoint: this.$store.state.huddle.config.endpoint,
                      };
                      console.log("Calling SocketClient with", options);
                      SocketClient.connect(options);
                    })
                  )
              );
          }
        }.bind(this)
      );
    }

    // this.intro.map(function(text) {
    for (let i = 0; i < this.intro.length; i++) {
      const text = this.intro[i];
      window.setTimeout(
        function () {
          this.$store.dispatch("huddle/message/pushMessage", {
            text: text,
            author: "huddle",
          });
        }.bind(this),
        (i + 1) * this.introInterval
      );
    }
    if (this.example) {
      window.setTimeout(
        function () {
          const message = {
            text: this.example,
            author: "user",
            type: "text",
          };
          this.$store.dispatch("huddle/message/send", message);
        }.bind(this),
        (this.intro.length == 0 ? 1 : this.intro.length) * this.introInterval
      );
    }

    if (this.placeholder !== "") {
      this.$store.dispatch("huddle/config/setPlaceholder", this.placeholder);
    }
  },
};
</script>