import handler from './index'

const client = {
  connect: options => {
    client.socket = new WebSocket(options.endpoint)
    client.socket.onopen = openEvent => {
      console.debug('ws::open : connection established', client.socket)
      handler.subscribeAll(options.store)
      options.store.dispatch("huddle/socket/setConnected", true)
    }

    client.socket.onerror = errorEvent => {
      console.debug('WebSocket ERROR:', JSON.stringify(errorEvent, null, 4))
    }

    client.socket.onmessage = event => {
      const msg = JSON.parse(event.data)
      switch(msg.type) {
        case "ping":
          break;
        case "welcome":
          break;
        default:
          handler.handle(msg, options.store)
        }
    }
  }
}

export default client
