import api from '../../../api'

const state = {
  endpoint: 'https://api.huddleai.com/',
  placeholder: 'Ask me something',
  token: undefined,
  session: {
    token: undefined,
    expires: undefined
  }
}

const actions = {
  setEndpoint ({ commit }, endpoint) {
    commit('storeEndpoint', endpoint)
  },
  setToken ({ commit }, token) {
    commit('storeToken', token)
  },
  setPlaceholder ({ commit }, placeholder) {
    commit('storePlaceholder', placeholder)
  },
  async createAuthSession ({ commit, state, dispatch }) {
    const response = await api.config.login(state.token)
    if (response.data.session !== undefined) {
      commit('setSession', response.data.session)

      const expiresIn = (new Date(state.session.expires) - new Date()) / 1000
      if (expiresIn > 60 * 60)
        console.log('Expires in', expiresIn / 60 / 60, 'hours')
      else if (expiresIn > 60)
        console.log('Expires in', expiresIn / 60, 'minutes')
      else console.log('Session expires in', expiresIn.toFixed(1), 'seconds')

      window.setTimeout(() =>
        dispatch('createAuthSession'), (expiresIn - 30) * 1000
      )
    }
  },
  
}

const mutations = {
  storeEndpoint (state, endpoint) {
    state.endpoint = endpoint
  },
  storeToken (state, token) {
    state.token = token
  },
  storePlaceholder (state, placeholder) {
    state.placeholder = placeholder
  },
  setSession (state, session) {
    state.session.token = session.token
    state.session.expires = session.expires
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
