<template>
  <div class="handlebars" v-html="renderedContent" />
</template>

<script>
import HandlebarsHelper from "../../lib/handlebars";
export default {
  name: "handlebars-message",
  props: {
    message: Object,
    required: true
  },
  computed: {
    id() {
      return this._uid;
    },
    renderedContent() {
      return HandlebarsHelper.render(
        this.message.data.template,
        Object.keys(this.message.data.results).reduce(
          function(result, key) {
            result[key] =
              this.message.data.results[key] === null
                ? "Not Available"
                : this.message.data.results[key];
            return result;
          }.bind(this),
          {}
        )
      );
    }
  }
};
</script>


<style lang="scss">
@import "../../assets/scss/palette.scss";

.handlebars {
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.3em;
    border-bottom: 1px solid;
    margin-top: 10px;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.2em;
  }
  ul {
    list-style: initial;
    margin-left: 50px;
  }
  q {
    width: 90%;
    display: block;
    margin: auto;
    text-align: center;
  }

  .data-point {
    font-size: 1.5em;
    background: inherit;
  }
  .title.is-3,
  .title.is-4 {
    margin-bottom: 0 !important;
    margin-top: 20px !important;
  }
  a {
    color: #666666;
    text-decoration: underline;
  }
  p {
    padding: 5px;
  }

  table.is-small {
    font-size: 0.6em;
  }

  table {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #bac1b8;
    color: #777;
    margin: auto;
    th {
      color: #444;
    }
    th,
    td {
      padding: 4px;
      vertical-align: middle;
      border: 1px solid #bac1b8;
    }
    tbody tr:last-child td,
    tbody tr:last-child th {
      border-bottom-width: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.metadata {
  text-align: right;
  border-top: 1px solid #ccc;
  width: 100%;
}
.metadata .item {
  margin-left: 14px;
}
.metadata .item .value {
  font-weight: bold;
}
</style>
